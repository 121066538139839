<template>
  <div ref="elementRef" @click="handlePromotionClick">
    <div class="relative">
      <base-picture
        v-if="responsiveMedia.images"
        v-bind="responsiveMedia.images"
        class="w-full"
        fit="cover"
        :lazy
      />
      <vf-video
        v-else-if="responsiveMedia.video"
        v-bind="responsiveMedia.video"
        ref="videoRef"
        :autoplay="!lazy"
        class="w-full"
        :controls
        loop
        muted
      />
      <base-link
        v-if="mediaLink"
        aria-hidden="true"
        class="absolute-0"
        tabindex="-1"
        :target="mediaLink.targetAttribute"
        :to="mediaLink.url"
      />
    </div>
    <div class="mt-4 f-col px-4 space-y-2 lg:space-y-4" :class="contentAlignment[alignment]">
      <p v-if="title" v-style:c="titleColor" :class="titleStyle" data-test-id="cms-linked-card-title">
        <base-link v-if="mediaLink" :target="mediaLink.targetAttribute" :to="mediaLink.url">
          {{ title }}
        </base-link>
        <template v-else>
          {{ title }}
        </template>
      </p>
      <p
        v-if="subtitle"
        v-style:c="subtitleColor"
        :class="subtitleStyle"
        data-test-id="cms-linked-card-text"
      >
        {{ subtitle }}
      </p>
      <div
        v-if="linkType !== 'No-CTA' && targets.length"
        class="f-col gap-4 md:flex-row md:wrap lg:gap-6 not-first:pt-4"
        :class="[ctaAlignment[alignment], equalTargetSizeClass]"
        :style="{ '--cols': cols }"
      >
        <cms-shared-button v-for="(target, i) in targets" :key="i" v-bind="target" :size="size" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  SectionContextKey,
  type SectionProvider
} from '#content/components/cms/section/context'
import type { Target } from '#types/target'
import type { LinkedCardAlignment, LinkedCardContent } from '#types/components/cms/linked-card'

const { content, isPartOfCollection } = withDefaults(defineProps<{
  content: LinkedCardContent
  controls?: boolean
  isPartOfCollection?: boolean
}>(), { controls: true })

const {
  alignment,
  equalTargetSize,
  linkType,
  media,
  mediaTarget,
  promotionTracking,
  subtitle,
  subtitleColor,
  subtitleStyle,
  targets = [] as Target[],
  title,
  titleColor,
  titleStyle
} = content

const { size } = useAppConfig().components.cms.sharedButton
const { getMedia } = useCms()
const { elementRef, handlePromotionClick } = usePromotionTracking(promotionTracking, content.name)

const { lazy } = inject(SectionContextKey, {} as SectionProvider)
const videoRef = ref()
const mediaLink = mediaTarget || targets[0]
const responsiveMedia = getMedia(media)

const contentAlignment: Partial<Record<LinkedCardAlignment, string>> = {
  center: 'items-center text-center'
}

const ctaAlignment: Record<LinkedCardAlignment, string> = {
  left: 'items-start',
  center: '<md:items-center md:justify-center'
}

const equalTargetSizeClass = targets.length > 1 && [
  getValueForBreakpoint('sm', equalTargetSize) && '<md:grid <md:cols-$cols <md:auto-rows-fr',
  getValueForBreakpoint('md', equalTargetSize) && 'md:grid cols-$cols auto-rows-fr items-stretch',
  !equalTargetSize.md && getValueForBreakpoint('lg', equalTargetSize) && 'lg:grid cols-$cols auto-rows-fr items-stretch'
].filter(Boolean).join(' ')

const cols = isPartOfCollection
  ? 'repeat(1,minmax(0,1fr))'
  : targets.map((target) => target.style?.sm === 'text-link' ? 'max-content' : 'minmax(0,1fr)').join(' ')

onMounted(() => videoRef.value?.play().catch((e) => log.error(e)))
</script>
